import React from "react";
import AnimateHeight from "react-slidedown";
import HeroSection from "../Component/home/HeroSection";
import ServiceSection from "../Component/home/ServiceSection";
import AboutSection from "../Component/home/AboutSection";
import CounterSection from "../Component/home/CounterSection";
import ChooseUsSection from "../Component/home/ChooseUsSection";
import SkillSection from "../Component/home/SkillSection";
import CaseStudySection from "../Component/home/CaseStudySection";
import TestimonialSection from "../Component/home/TestimonialSection";
import BrandSection from "../Component/home/BrandSection";
import TeamSection from "../Component/home/TeamSection";
import BlogSection from "../Component/home/BlogSection";
import CtaSection from "../Component/home/CtaSection";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <>
      <Helmet>
        <title>Bilvaleaf: Web Design, Digital Marketing, Graphics & BPO/KPO</title>
        <meta name="description" content="Providing Web Development, web portals, digital marketing, and call center services." />
        <link rel="canonical" href="https://bilvaleaf.com/" />
      </Helmet>
      <div>
        <AnimateHeight
          duration={500} // Animation duration in milliseconds
          height={isExpanded ? "auto" : 0} // Set to 'auto' for dynamic height
        >
          <HeroSection />
          <ServiceSection />
          <AboutSection />
          <CounterSection />
          <ChooseUsSection />
          <SkillSection />
          <CaseStudySection />
          <TestimonialSection />
          {/* <BrandSection /> */}
          <TeamSection />
          <BlogSection />
          <CtaSection />
        </AnimateHeight>
      </div>
    </>
  );
};

export default Home;
