import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ctaIcon2 from '../assets/images/cta-icon2.png';
import ctaBg from '../assets/images/bg/cta-bg.jpg';
import pageBanner from '../assets/images/bg/blog.jpg';
import sideBarImg from '../assets/images/blog/sidebar-img.jpg';
import { HTPPURL } from '../constant/Url';
import { Data } from './Data';
import { Helmet } from 'react-helmet-async';
import MetaTags from 'react-meta-tags';

export const BlogSingle = () => {
    const [singleblog, setSingleBlog] = useState();
    const [contactform, setContactForm] = useState({
        name: "",
        email: "",
        subject: "Blog single",
        message: ""
    });
    const { title } = useParams();
    function shuffleArray(array) {
        // Using Fisher-Yates shuffle algorithm
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    const scrollToDiv = () => {
        const element = document.getElementById('showData');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Assuming 'Data' is your array of blog data
    const shuffledData = shuffleArray(Data);
    useEffect(() => {
        const singleblogData = Data?.find((elem) => elem?.title.toLowerCase() == title.replaceAll('-', ' '));
        setSingleBlog(singleblogData);
    }, [singleblog, title]);

    const onSubmitForm = async (e) => {
        e.preventDefault();
        const { name, email, subject, message } = contactform;
        if (!name || !email || !message) {
            alert('All fields are required!');
        } else {
            const response = await fetch(HTPPURL + 'api/contact', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(contactform)
            });
            const responsedata = await response.json();
            if (responsedata?.status) {
                setContactForm({ ...contactform, name: "", email: "", message: "" });
                alert(responsedata?.message);
            }
        }
    }
    const canonicalUrl = `https://bilvaleaf.com/blog-details/${title}`;

    useEffect(() => {
    }, [title]);

    return (
        <>
            <MetaTags>
                <title>{`${title.replaceAll('-', ' ')}`}</title>
                <meta property="og:title" content={`${title.replaceAll('-', ' ')}`} />
                <meta name="og:description" content={`${title.replaceAll('-', ' ')}`} />
                <link rel="canonical" href={canonicalUrl} />
                <meta property="og:image" content={`https://bilvaleaf.com${singleblog?.mainImg ? singleblog?.mainImg : singleblog?.imagecls}`} />
                <link rel="og:url" href={canonicalUrl} />
                <meta property="og:type" content="article" />
            </MetaTags>
            {/* Page Banner Start */}
            <div className="section page-banner-section" style={{ backgroundImage: `url(${pageBanner})` }}>
                <div className="shape-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt"
                            strokeLinejoin="miter" opacity="0.302" fill="none"
                            d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
                    </svg>
                </div>
                <div className="shape-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt"
                            strokeLinejoin="miter" fill="none"
                            d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
                    </svg>
                </div>
                <div className="container">
                    <div className="page-banner-wrap">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-banner text-center">
                                    <h2 className="title">Blog Details</h2>
                                    <ul className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">Blog Details</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Page Banner End */}

            <div className="section blog-details-section section-padding-02" id="showData">
                <div className="container">
                    <div className="blog-details-wrap">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8">
                                <div className="blog-details-post">
                                    <div className="single-blog-post single-blog">
                                        <div className="blog-image">
                                            <Link to="#"><img src={singleblog?.mainImg ? singleblog?.mainImg : singleblog?.imagecls} alt={singleblog?.mainImg} /></Link>
                                            <div className="top-meta">
                                                <span className="date"><span>{singleblog?.day}</span>{singleblog?.month}</span>
                                            </div>
                                        </div>
                                        <div className="blog-content">
                                            <div className="blog-meta">
                                                {/* <span className="tag"><i className="far fa-bookmark"></i> Category / Business</span> */}
                                                <span><i className="fas fa-user"></i> <a href="#">{singleblog?.name}</a></span>
                                                {/* <span><i className="far fa-comments"></i> 0 Comments</span> */}
                                            </div>
                                            <h3 className="title">{singleblog?.title}</h3>
                                            <p className="text">{singleblog?.text}</p>
                                        </div>
                                    </div>
                                    <div className="blog-details-content">
                                        {/* <div className="blog-quote"> */}
                                        {/* <blockquote className="blockquote"> */}
                                        <p className="text"><div dangerouslySetInnerHTML={{ __html: singleblog?.text1 }} /></p>
                                        {/* </blockquote> */}
                                        {/* </div> */}
                                        <div className="blog-details-text">
                                            <p>{singleblog?.text2}</p>
                                        </div>
                                        <div className="blog-details-tag-share">
                                            <div className="blog-details-tag">
                                                <div className="sidebar-widget">
                                                    <span className="label"></span>

                                                </div>
                                            </div>
                                            <div className="blog-details-share">
                                                <ul className="social-share">
                                                    <li><a className="share-facebook" href="https://www.facebook.com/profile.php?id=61557420182345" target='_blank' title="FACEBOOK"><i className="fab fa-facebook-f"></i></a></li>
                                                    <li><a className="share-twitter" href="https://www.instagram.com/bilvaleaf/" target="_blank" title="INSTAGRAM"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a className="share-pinterest" href="https://twitter.com/bilvaleaf" target="_blank" title="TWITTER"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a className="share-pinterest" href="https://www.linkedin.com/in/bilvaleaf-private-limited-7b71a12bb/" target="_blank" title="LINKEDIN"><i className="fab fa-linkedin-in"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="comment-wrap">
                                            <div className="comment-form">
                                                {/* <h3 className="comment-title">Comments (3)</h3> */}
                                                <p>Your email address will not be published. Required fields are marked *</p>
                                                <div className="comment-form-wrap">
                                                    <form method='post' onSubmit={(e) => onSubmitForm(e)}>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="single-form">
                                                                    <input className="form-control" name="name" type="text" placeholder="Your Name" value={contactform.name} onChange={(e) => setContactForm({ ...contactform, name: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="single-form">
                                                                    <input className="form-control" name="email" type="email" placeholder="Your Email" value={contactform.email} onChange={(e) => setContactForm({ ...contactform, email: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="single-form">
                                                                    <textarea className="form-control" placeholder="Your Message" name="message" value={contactform.message} onChange={(e) => setContactForm({ ...contactform, message: e.target.value })}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-btn">
                                                                    <button className="btn" type="submit">Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="blog-sidebar">

                                    <div className="sidebar-widget">
                                        <div className="widget-title">
                                            <h3 className="title">Popular Posts</h3>
                                        </div>
                                        <div className="recent-posts">
                                            <ul>
                                                {
                                                    shuffledData.slice(0, 3).map((elem) => {
                                                        const { id, title, image, day, month } = elem;
                                                        return (

                                                            <li key={id} title={title} onClick={scrollToDiv}>
                                                                <Link className="post-link" to={`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`}>
                                                                    <div className="post-thumb">
                                                                        <img src={image} alt={image} width="70px" height="70px" />
                                                                    </div>
                                                                    <div className="post-text">
                                                                        <h4 className="title">{title.replace(':', '')}</h4>
                                                                        <span className="post-meta"><i className="far fa-calendar-alt"></i> {month} {day}, 2024</span>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="sidebar-widget">
                                        <div className="widget-banner" style={{ backgroundImage: `url(${sideBarImg})` }}>
                                            <div className="banner-content">
                                                <h4 className="title">The leading platform</h4>
                                                <Link className="btn btn-class" to="/contact">Get Started</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sidebar-widget">
                                        <div className="widget-title">
                                            <h3 className="title">Categories</h3>
                                        </div>
                                        <ul className="category">
                                            <li className="cate-item"><Link to="#"><i className="flaticon-next"></i> Web Development <span className="post-count">3</span></Link></li>
                                            <li className="cate-item"><Link to="#"><i className="flaticon-next"></i> Digital Marketing <span className="post-count">2</span></Link></li>
                                            <li className="cate-item"><Link to="#"><i className="flaticon-next"></i> Graphic Design <span className="post-count">2</span></Link></li>
                                            <li className="cate-item"><Link to="#"><i className="flaticon-next"></i> BPO/KPO <span className="post-count">2</span></Link></li>
                                        </ul>
                                    </div>
                                    <div className="sidebar-widget">
                                        <div className="widget-title">
                                            <h3 className="title">Tags</h3>
                                        </div>
                                        <ul className="sidebar-tag">
                                            <li><Link to="/service">Web Development</Link></li>
                                            <li><Link to="/service">Digital Marketing</Link></li>
                                            <li><Link to="/service">Graphic designing</Link></li>
                                            <li><Link to="/service">BPO/KPO</Link></li>
                                            <li><Link to="/service">Logo designing</Link></li>
                                            <li><Link to="/service">Front Office Outsourcing</Link></li>
                                            <li><Link to="/service">Publication Graphic Design</Link></li>
                                            <li><Link to="/service">Single page website</Link></li>
                                            <li><Link to="/service">Affiliate and Influencer Marketing</Link></li>
                                            <li><Link to="/service">Static website designs</Link></li>
                                            <li><Link to="/service">Dynamic Websites</Link></li>
                                            <li><Link to="/service">Social Media Marketing (SMM)</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Cta Start --> */}
            <div className="section techwix-cta-section-02 section-padding-02">
                <div className="container">
                    <div className="cta-wrap" style={{ backgroundImage: `url(${ctaBg})` }}>
                        <div className="row align-items-center">
                            <div className="col-xl-9 col-lg-8">
                                <div className="cta-content">
                                    <div className="cta-icon">
                                        <img src={ctaIcon2} alt="cta-icon2.png" />
                                    </div>
                                    <p>We’re Delivering the best customer Experience</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="cta-btn">
                                    <Link className="btn btn-class" to="/contact">+919816333105</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Cta End --> */}
        </>
    )
}

export default BlogSingle;
