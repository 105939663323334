import React, { useEffect, useState } from "react";
import blog1 from '../../assets/images/blog/blog-1.jpg';
import blog2 from '../../assets/images/blog/blog-2.jpg';
import blog3 from '../../assets/images/blog/blog-3.jpg';
import { Data } from "../../pages/Data";
import { Link, useNavigate } from "react-router-dom";

const BlogSection = () => {
  const navigate = useNavigate();
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToDiv = (path) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById('showData');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };
  return (
    <div className="section techwix-blog-section section-padding-02">
      <div className="container">
        {/* Section Title Start */}
        <div className="section-title text-center">
          <h4 data-aos={isWideScreen ? "fade-left" : 'undefined'} className="sub-title">Latest Insights</h4>
          <h2 data-aos={isWideScreen ? "fade-right" : "undefined"} className="title">Trends and Tips from Our Blogs</h2>
        </div>
        {/* Section Title End */}
        <div className="techwix-blog-wrap">
          <div className="row">
            {
              Data && Data.map((elem) => {
                const { id, title, image, name, day, month } = elem;
                return (
                  id < 4 ? (
                    <div key={id} title={title} data-aos={isWideScreen ? "fade-down" : "undefined"} className="col-lg-4 col-md-6" 
                    onClick={() => scrollToDiv(`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`)}
                   >
                      {/* Single Blog Start */}
                      <div className="single-blog">
                        <div className="blog-image">
                          <Link to={`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`}>
                            <img src={image} alt={title} />
                          </Link>
                          <div className="top-meta">
                            <span className="date">
                              <span>{day}</span>{month}
                            </span>
                          </div>
                        </div>
                        <div className="blog-content">
                          <div className="blog-meta">
                            <span>
                              <i className="fas fa-user"></i>
                              <Link to={`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`}>{name}</Link>
                            </span>
                          </div>
                          <h3 className="title">
                            <Link to={`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`}>
                              {title}
                            </Link>
                          </h3>
                          <div className="blog-btn">
                            <Link className="blog-btn-link" to={`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`}>
                              Read Full <i className="fas fa-long-arrow-alt-right"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* Single Blog End */}
                    </div>
                  ) : null
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
