import React, { useState, useEffect } from "react";
import aboutBg from '../../assets/images/about-big2.jpg';
import aboutSm from '../../assets/images/about-sm2.jpg';
import { Link } from "react-router-dom";

const AboutSection = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <div className="section techwix-about-section-04 section-padding">
      <div className="shape-1"></div>
      <div className="container">
        {/* About Wrapper Start */}
        <div className="about-wrap">
          <div className="row">
            <div className="col-lg-6">
              {/* About Content Wrap Start */}
              <div className="about-content-wrap">
                <div className="section-title">
                  <h3 data-aos={isWideScreen ? "fade-left": "undefined"} className="sub-title">Who we are</h3>
                  <h2 data-aos={isWideScreen ?"fade-right" : "undefined"} className="title">
                    Designing, Marketing and Transforming dreams into Digital Realities.
                  </h2>
                </div>
                <p className="text">
                  We are a dynamic team comprising web developers, graphic designers, digital marketing experts, and BPO service providers, united by a shared passion for innovation and excellence. Together, we are more than just a team – we are your partners in success, committed to helping you achieve your goals and surpassing your expectations every step of the way.
                </p>
                {/* About Author Info Wrap Start */}
                <div className="about-author-info-wrap">
                  <div className="about-author">
                    {/* <img src="assets/images/sign.png" alt="sign.png"" /> */}
                    <h3 className="name">Ramanuj Barai</h3>
                    <span className="designation">CEO, Director</span>
                  </div>
                  <div className="about-info">
                    <p>Drop to Ask any question</p>
                    <Link to="/contact">Contact</Link>
                    {/* <h3 className="number">0123-456-7890</h3> */}
                  </div>
                </div>
                {/* About Author Info Wrap End */}
              </div>
              {/* About Content Wrap End */}
            </div>
            <div className="col-lg-6">
              {/* About Image Wrap Start */}
              <div className="about-img-wrap">
                {/* <div className="play-btn-02">
                  <Link className="popup-video popup-youtube" to="https://www.youtube.com/watch?time_continue=3&v=_X0eYtY8T_U">
                    <i className="popup-vimeo fas fa-play"></i>
                  </Link>
                </div> */}
                <div className="about-img about-img-big">
                  <img src={aboutBg} alt="about-big2.jpg" />
                </div>
                <div data-aos={isWideScreen ?"zoom-in-up" : "undefined"} className="about-img about-img-sm">
                  <img src={aboutSm} alt="about-sm2.jpg" />
                </div>
              </div>
              {/* About Image Wrap End */}
            </div>
          </div>
        </div>
        {/* About Wrapper End */}
      </div>
    </div>
  );
};

export default AboutSection;
