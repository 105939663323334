import React from 'react';
import { Link } from 'react-router-dom';
import pageBanner from '../assets/images/bg/blog.jpg';
import { Data } from './Data';
import { Helmet } from 'react-helmet-async';

const BlogList = () => {
    const scrollToDiv = () => {
        const element = document.getElementById('showData');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <>
            <Helmet>
                <title>Insights and Updates from Bilvaleaf Blog | Web Design, Development & More</title>
                <meta name="description" content="Explore Bilvaleaf's blog for the latest trends, tips, and insights in web development, graphic design, BPO/KPO, and digital marketing." />
                <link rel="canonical" href="https://bilvaleaf.com/blog" />
            </Helmet>
            {/* Page Banner Start */}
            <div className="section page-banner-section" style={{ backgroundImage: `url(${pageBanner})` }}>
                <div className="shape-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
                    </svg>
                </div>
                <div className="shape-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
                    </svg>
                </div>
                <div className="container">
                    <div className="page-banner-wrap">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-banner text-center">
                                    <h2 className="title">Bilvaleaf's Blogs</h2>
                                    <ul className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Blogs</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Page Banner End */}

            {/* Blog Start */}
            <div className="section techwix-blog-grid-section section-padding">
                <div className="container">
                    <div className="techwix-blog-grid-wrap">
                        <div className="row">
                            {
                                Data && Data.map((elem) => {
                                    const { id, title, image, name, day, month } = elem;
                                    return (
                                        <div className="col-lg-4 col-md-6" key={id} title={title} onClick={scrollToDiv}>
                                            <div className="single-blog">
                                                <div className="blog-image">
                                                    <Link to={`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`}><img src={image} alt={title} /></Link>
                                                    <div className="top-meta">
                                                        <span className="date"><span>{day}</span>{month}</span>
                                                    </div>
                                                </div>
                                                <div className="blog-content">
                                                    <div className="blog-meta">
                                                        <span><i className="fas fa-user"></i> <Link to={`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`}>{name}</Link></span>
                                                    </div>
                                                    <h3 className="title"><Link to={`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`}>{title}</Link></h3>
                                                    <div className="blog-btn">
                                                        <Link className="blog-btn-link" to={`/blog-details/${title.replaceAll(' ', '-').toLowerCase()}`}>Read Full <i className="fas fa-long-arrow-alt-right"></i></Link>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* Blog End */}
        </>
    )
}
export default BlogList;
