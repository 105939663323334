import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import pageBanner from '../assets/images/bg/service.jpg';
import CtaSection from '../Component/home/CtaSection';
import ctabg5 from '../assets/images/bg/cta-bg5.jpg';
import ctaIcon from '../assets/images/cta-icon2.png';
import testbg4 from '../assets/images/bg/testi-bg4.jpg'
import testi3 from '../assets/images/testi-3.jpg';
import testi4 from '../assets/images/testi-4.jpg';
import testicon from '../assets/images/testi-icon.png';
import brand1 from '../assets/images/brand/brand-1.png';
import brand2 from '../assets/images/brand/brand-2.png';
import brand3 from '../assets/images/brand/brand-3.png';
import brand4 from '../assets/images/brand/brand-4.png';
import brand5 from '../assets/images/brand/brand-5.png';
import ctaBg from '../assets/images/bg/cta-bg.jpg';
import ctaIcon2 from '../assets/images/cta-icon2.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


const Service = () => {
    return (
        <>
            <Helmet>
                <title>Comprehensive Web Development, Graphic Design, BPO/KPO & Digital Marketing Services | Bilvaleaf</title>
                <meta name="description" content="Discover our comprehensive services: web development, graphic design, BPO/KPO solutions, and cutting-edge digital marketing strategies." />
                <link rel="canonical" href="https://bilvaleaf.com/service" />
            </Helmet>
            {/* Page Banner Start */}
            <div className="section page-banner-section" style={{ backgroundImage: `url(${pageBanner})` }}>
                <div className="shape-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="726.5px" height="726.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" opacity="0.302" fill="none" d="M28.14,285.269 L325.37,21.217 C358.860,-8.851 410.655,-5.808 440.723,28.14 L704.777,325.36 C734.846,358.859 731.802,410.654 697.979,440.722 L400.955,704.776 C367.132,734.844 315.338,731.802 285.269,697.978 L21.216,400.954 C-8.852,367.132 -5.808,315.337 28.14,285.269 Z" />
                    </svg>
                </div>
                <div className="shape-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="972.5px" height="970.5px">
                        <path fillRule="evenodd" stroke="rgb(255, 255, 255)" strokeWidth="1px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M38.245,381.102 L435.258,28.158 C480.467,-12.32 549.697,-7.964 589.888,37.244 L942.832,434.257 C983.23,479.466 978.955,548.697 933.746,588.888 L536.733,941.832 C491.524,982.23 422.293,977.955 382.103,932.745 L29.158,535.732 C-11.32,490.523 -6.963,421.293 38.245,381.102 Z" />
                    </svg>
                </div>
                <div className="container">
                    <div className="page-banner-wrap">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="page-banner text-center">
                                    <h2 className="title">Bilvaleaf’s Services</h2>
                                    <ul className="breadcrumb justify-content-center">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Services</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Page Banner End */}

            {/* Choose Us Start */}
            <div className="section techwix-choose-us-section techwix-choose-us-section-04 section-padding">
                <div className="container">
                    <div className="choose-us-wrap">
                        <div className="choose-us-content-wrap">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 col-md-6 web-des">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon9.png" alt="ser-icon9.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Single page Website</a></h3>
                                            <p>In a Single page website instead of navigating through multiple pages, all the content is presented on a single page that users can scroll through vertically or horizontally.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 web-des">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/counter-3.png" alt="counter-3.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Static website designs</a></h3>
                                            <p>Static website designs are composed of fixed pages with content that does not change unless the developer manually updates the HTML files.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 web-des">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon10.png" alt="ser-icon10.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Dynamic Website Design</a></h3>
                                            <p>Unlike static websites, dynamic websites can display different content to users based on their interactions, preferences, and data stored in the backend.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 web-des">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon12.png" alt="ser-icon12.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Responsive Website Design</a></h3>
                                            <p>Responsive website design (RWD) is an approach to web design that ensures web pages render well on a variety of devices and window or screen sizes.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 digital-mark">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/counter-1.png" alt="counter-1.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Search Engine Optimization (SEO)</a></h3>
                                            <p>SEO involves keyword research, on-page optimization , off-page optimization , and technical SEO which ensure site structure and performance.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 digital-mark">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/counter-2.png" alt="counter-2.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Social Media Marketing</a></h3>
                                            <p>SMM utilizes social media platforms  to connect with the target audience, build brand awareness, drive website traffic, and generate leads or sales.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 digital-mark">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon21.png" alt="ser-icon21.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Search Engine Marketing</a></h3>
                                            <p>Search Engine Marketing/Pay-per-Click involves using paid advertising to promote websites and increase their visibility in search engine results pages (SERPs).</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 digital-mark">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Affiliate and Influencer Marketing</a></h3>
                                            <p>Affiliate marketing is a performance-based strategy where affiliates (publishers or influencers) promote products on behalf of a business  through their referral link.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 grapic-design">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Visual identity graphic</a></h3>
                                            <p>Visual identity graphic design, often referred to as branding, focuses on creating a consistent visual identity for a brand or organization.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 grapic-design">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Marketing & advertising graphic</a></h3>
                                            <p>Marketing and advertising graphic design involves creating visual assets used in marketing campaigns and promotional materials.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 grapic-design">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Publication Graphic</a></h3>
                                            <p>Publication graphic design focuses on layout and formatting for printed or digital publications such as books, magazines, newspapers, and ebooks.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 grapic-design">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">User interface graphic</a></h3>
                                            <p>User Interface (UI) graphic design focuses on designing the visual elements of a digital interface with the goal of enhancing user interaction and experience.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 bpo-kpo">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Back Office Outsourcing</a></h3>
                                            <p>It refer to internal business functions such as data entry, data management, payroll processing, human resources (HR) management, accounting, and finance.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 bpo-kpo">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Front Office Outsourcing</a></h3>
                                            <p>It involve customer-facing activities such as customer support, technical support, helpdesk services, inbound and outbound sales, lead generation, and CRM.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 bpo-kpo">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Knowledge Process Outsourcing</a></h3>
                                            <p>Knowledge Process Outsourcing involves outsourcing high-value, knowledge-based processes that require specialized domain expertise and analytical skills.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6 bpo-kpo">
                                    <div className="choose-us-item-02">
                                        <div className="choose-us-img">
                                            <img src="assets/images/ser-icon22.png" alt="ser-icon22.png" />
                                        </div>
                                        <div className="choose-us-content">
                                            <h3 className="title"><a href="#">Onshore/Domestic BPO</a></h3>
                                            <p>Onshore/Domestic BPO outsourcing is exactly as it sounds: the act of outsourcing and receiving services within the boundaries of a specific country.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Choose Us End */}

            {/* Cta Start */}
            <div className="section techwix-cta-section-04 techwix-cta-section-06 techwix-cta-section-07">
                <div className="container">
                    <div className="cta-wrap" style={{ backgroundImage: `url(${ctabg5})` }}>
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="cta-left">
                                    <div className="section-title">
                                        <h2 className="title white">To make requests for further information, contact us </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="cta-info text-center">
                                    <div className="cta-icon">
                                        <img src={ctaIcon} alt="ser-icon22.png" />
                                    </div>
                                    <div className="cta-text">
                                        <p>Call Us For Any inquiry</p>
                                        <h3 className="number">+91 9816333105</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Cta End */}

            {/* <!-- Testimonial Start  --> */}
            {/* <div className="section bg-cover techwix-testimonial-section-02 techwix-testimonial-section-03 techwix-testimonial-section-05 section-padding" style={{ backgroundImage: `url(${testbg4})` }}>
                <div className="container">
                    <div className="testimonial-wrap-02">
                        <div className="section-title text-center">
                            <h3 className="sub-title">Testimonial</h3>
                            <h2 className="title">25k+ satisfied clients worldwide</h2>
                        </div>
                        <div className="testimonial-content-wrap-02">
                            <div className="swiper-container testimonial-02-active">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        spaceBetween={40}
                                        slidesPerView={1}
                                        breakpoints={{
                                            // When screen width is >= 640px (tablet and above), show 3 slides
                                            640: {
                                                slidesPerView: 2,
                                            },
                                        }}
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        loop={true}
                                        autoplay={{
                                            delay: 3000, // 3000 ms (3 seconds)
                                            disableOnInteraction: false,
                                          }}
                                        pagination={{ clickable: true }}
                                    >
                                        <div className="swiper-slide">
                                            <SwiperSlide>
                                                <div className="single-testimonial-02">
                                                    <div className="testimonial-thumb">
                                                        <img src={testi3} alt="ser-icon22.png" />
                                                    </div>
                                                    <div className="testimonial-content">
                                                        <img src={testicon} alt="ser-icon22.png" />
                                                        <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                                                        <span className="name">Mike Holder </span>
                                                        <span className="designation"> / CEO, Harlond inc</span>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </div>
                                        <div className="swiper-slide">
                                            <SwiperSlide>
                                                <div className="single-testimonial-02">
                                                    <div className="testimonial-thumb">
                                                        <img src={testi4} alt="ser-icon22.png" />
                                                    </div>
                                                    <div className="testimonial-content">
                                                        <img src={testicon} alt="ser-icon22.png" />
                                                        <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                                                        <span className="name">Mike Fermalin </span>
                                                        <span className="designation"> / CEO, Harlond inc</span>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </div>
                                        <div className="swiper-slide">
                                            <SwiperSlide>
                                                <div className="single-testimonial-02">
                                                    <div className="testimonial-thumb">
                                                        <img src={testi3} alt="testi-3.jpg" />
                                                    </div>
                                                    <div className="testimonial-content">
                                                        <img src={testicon} alt="testi-3.jpg" />
                                                        <p>Accelerate innovation with world-class tech teams Beyond more stoic this along goodness hey this this wow manatee </p>
                                                        <span className="name">Mike Holder </span>
                                                        <span className="designation"> / CEO, Harlond inc</span>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </div>
                                    </Swiper>
                                </div>
                                <div className="swiper-pagination">{SwiperCore.use([Pagination])}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- Testimonial End  --> */}

            {/* Cta Start */}
            <div className="section techwix-cta-section-02 section-padding-02">
                <div className="container">
                    <div className="cta-wrap" style={{ backgroundImage: `url(${ctaBg})` }}>
                        <div className="row align-items-center">
                            <div className="col-xl-9 col-lg-8">
                                <div className="cta-content">
                                    <div className="cta-icon">
                                        <img src={ctaIcon2} alt="testi-3.jpg" />
                                    </div>
                                    <p>We’re Delivering the best customer Experience</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="cta-btn">
                                    <Link className="btn btn-class" to="/contact">+919816333105</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Cta End */}
        </>
    )
}
export default Service;
