import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png"


const Footer = () => {
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    return (
        <>
            <div className="section footer-section footer-section-03" style={{ backgroundImage: " url(assets/images/bg/footer-bg.jpg)" }}>
                <div className="container">
                    <div className="footer-widget-wrap">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-widget-about">
                                    <Link className="footer-logo" to="/"><img src={logo} alt="logo.png" title="Bilvaleaf" /></Link>
                                    <p>Whether you're a small business, a startup, or a large enterprise, Bilvaleaf Private Limited is here to bring your online presence to the next level.</p>
                                    <div data-aos={isWideScreen ? "fade-right" : "undefined"} className="footer-social">
                                        <ul className="social">
                                            <li>
                                                <a className="facbook" href="https://www.facebook.com/profile.php?id=61560439168465" target="_blank" title="FACEBOOK">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="instagram" href="https://www.instagram.com/bilvaleaf/" target="_blank" title="INSTAGRAM">
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="twitter" href="https://twitter.com/bilvaleaf" target="_blank" title="TWITTER">
                                                    X
                                                </a>
                                            </li>
                                            <li>
                                                <a className="innkedin" href="https://www.linkedin.com/company/bilvaleaf-private-limited/" target="_blank" title="LINKEDIN">
                                                    <i className="fab fa-linkedin-in"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="youtube" href="https://youtube.com/@bilvaleafprivatelimited?si=udxBlecKE6VLzeLV" target="_blank" title="YOUTUBE">
                                                    <i className="fab fa-youtube"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-widget">
                                    <h4 className="footer-widget-title">Useful Links</h4>
                                    <div className="widget-link">
                                        <ul className="link">
                                            <li data-bs-toggle="modal" data-bs-target="#staticBackdrop1"><Link to="#" title="Terms & Conditions">Terms & Conditions</Link></li>
                                            <li><Link to="/about" title="About Us">About Us</Link></li>
                                            <li data-bs-toggle="modal" data-bs-target="#staticBackdrop"><Link to="#" title="Privacy Policy">Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-widget">
                                    <h4 className="footer-widget-title">Our Services</h4>
                                    <div className="widget-link">
                                        <ul className="link">
                                            <li><Link to="/service" title="Web Development">Web Development</Link></li>
                                            <li><Link to="/service" title="Digital Marketing">Digital Marketing</Link></li>
                                            <li><Link to="/service" title="Graphic Design">Graphic Design</Link></li>
                                            <li><Link to="/service" title="BPO/KPO">BPO/KPO</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-widget">
                                    <h4 className="footer-widget-title">Contact Information</h4>
                                    <div className="widget-info">
                                        <ul>
                                            <li>
                                                <div className="info-icon">
                                                    <i className="flaticon-phone-call"></i>
                                                </div>
                                                <div className="info-text">
                                                    <span><Link to="tel:9816333105">+91 9816333105</Link></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="info-icon">
                                                    <i className="far fa-envelope-open"></i>
                                                </div>
                                                <div className="info-text">
                                                    <span><Link to="mailto: admin@bilvaleaf.com">admin@bilvaleaf.com</Link></span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="info-icon">
                                                    <i className="flaticon-pin"></i>
                                                </div>
                                                <div className="info-text">
                                                    <span>Hazaribagh, India</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright-area">
                    <div className="container">
                        <div className="footer-copyright-wrap">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="copyright-text text-center">
                                        <p>© {new Date().getFullYear()} BILVALEAF PRIVATE LIMITED All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Terms & Conditions</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                1. To carry all activities of the business of website development, website designing, website hosting, domain registration, website content writing,graphic design, data mining, data entry operation,Digital marketing, search engine optimization, online marketing, social media marketing, business promotion, marketing services,Application development and all other services related to website and internet on a consideration. <br /><br />
                                2. To Provide Web-Portals,mobile applications,Hybrid development and native development offering various services like eCommerce website,Business website,Blog website,Portfolio website,Event website,Personal website,Membership website, Nonprofit website,Informational website,Online forum,Community website, Startup website,Consulting website,Booking website,Petition website,School website,Hobby website,Interactive. <br /><br />
                                3. The objects to be pursued by the company on its incorporation are:website,Entertainment website,Wedding website,Travel website, Directory website, Landing page website,News and magazine website,Memorial website,Subscription website,Kid-friendly website and Apps for same for a consideration. <br /><br />
                                4. To carry on the business of providing outsourcing services for all processes, sub Processes, transactions, activities and all other work performed by business in various industries within India and across the world. This includes those process or sub processes that are enabled by information technology. It also includes data voice or video collection and processing, call centre services including in bound and out bound calling services of all kinds, technical support, managed data centre, managed technical centre, training centre, web support back office, business or financial analysis, scientific analysis, research work and analysis, storage, disaster recovery, accounting, pay roll, inventory management, customer relationship management, enterprises resources planning and to develop software, provide consultancy, software solution and services that are normally offered by the outsourcing business and information technology services providers, the software development houses and application services? providers for a consideration.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Privacy Policy</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                1. To purchase or otherwise acquire and hold on, invest, trade, deal in, mortgage, pledge, assign, sell, transfer or otherwise dispose of any goods, wares, merchandise and all movable property for carrying out business of the Company. <br/>
                                2. To purchase or otherwise acquire, assemble, install, construct, alter, equip. repair remodel, maintain, enlarge, operate, work, manage, control, (b) *Matters which are necessary for furtherance of the objects specified in clause 3(a) are hold, own, lease, rent, charter, mortgage, sell, convey or otherwise dispose of any buildings and structures, telephones and other communication facilities, data processing system and facilities, machinery, apparatus,instruments, fixtures and appliances in so far as the same may appertain to or be useful in the conduct of the business of the Company.<br/>
                                3. To purchase charter, hire, build or otherwise acquire any vehicles, vessels or craft of every description and to hold, own or work such vehicles, vessels or crafts for business of the Company.<br/>
                                4. To pay for any rights or property acquired by the Company and to remunerate any person or company .<br/>
                                5. To apply for purchase or otherwise acquire and protect and renew any patent rights, brevets of de invention, trademarks, designs, licenses, concessions and the like, conferring any exclusive or non exclusive or limited rights to their use, or any secret or other information as to any invention or research which may seem capable of being used for any of the purposes of the Company or conveniences, required for the purpose of carrying out any of the aforesaid business and to undertake, execute, carry out, dispose of or otherwise turn to account such contracts, monopolies or concessions.<br/>
                                6. To acquire from any person, firm, institution or body corporate, technical information, know-how, process engineering, manufacturing and operating data, plans, layout and blue prints useful for the design, erection, operation of plant and machinery required for any of the business of the company and to acquire any grant or license and other rights and benefits.<br/>
                                7. To enter into any arrangements with any Governments or any authority, supreme, municipal, local or otherwise that may seem beneficial to any of the Companies objects and to apply for, promote and obtain by any Act of any legislature, charter, rights, powers, privileges, concessions, grants, decrees, provisional orders, licenses or authorizations of Government Central or State, or any relevant authorities (local or otherwise) or any private party for enabling the Company to carry any of its objects into effect or for any purposes which may seem expedient and to oppose an proceedings or applications which may seem calculated to prejudice the interests of the Company.<br/>
                                8. To enter into arrangements with companies, firms and persons for promoting and increasing the manufacture, sale, purchase and maintenance of goods, articles or commodities of all and every kind and descriptions, either by buying, selling, letting on hire, hire purchase or easy payments system or by financing or assisting such other companies, firms or persons to do all or any of such last mentioned acts, transactions and things and in such manner as may be necessary or expedient and in connection with or for any of these purposes to enter into agreements, lend money, give guarantee or security or otherwise finance or assist all or such purposes on such terms and in such manner as may be desirable.<br/>
                                9. To advance, deposit or lend money, securities and property with or without security as may be thought proper to such persons, companies, corporations or firms and on such terms as may seem expedient and in particular to customers and others having dealings with the company and to release or discharge any debt or obligation owing to the Company.<br/>
                                10. Subject to the provisions of the Companies Act to receive money or deposit or loan and borrow any money in such manner and with or without allowance of interest thereupon as the Company shall deem fit and to secure the repayment of money borrowed, raised, received or owing by mortgage,ledge, charge or lien upon all or any of the property or assets of the Company (both present and future) and also by similar mortgage, charge, pledge or lien to secure the guarantee the performance by the Company or any other person, Company, firm or body Corporate of any obligation undertaken by the Company or any other person, Company, firm or body corporate as the case may be and to give the lenders or creditors the powers of sale and other powers as may seem expedient, provided however that the Company shall not do any banking business as defined in Banking Regulation Act, 1949 and money circulation business or Chit-Fund activities.<br/>
                                11. To draw, make, accept, endorse, discount, negotiate, execute and issue bills of exchange promissory notes, cheques, hundies , bills of lading, shipping documents, warrants debentures and other negotiable or transferable instruments.<br/>
                                12. To guarantee the performance of any contract or payment of money secured by or payable under or in respect of bonds, debentures, debenture stock, contracts, mortgages, charges, obligations and other securities of any Company or of any authority, Central, State, Municipal, local or otherwise or of any person, whomsoever, whether incorporated or not and generally to transact all kinds of guarantee business and to further transact all kinds of trust and agency business for attainment of the objects of the Company.<br/>
                                13. To invest any moneys of the Company not for the time being required for any of the purposes of the Company in such manner as may be thought proper and to hold, sell or otherwise deal with such investments.<br/>
                                14. To improve, manage, develop, grant rights or privileges in respect of, or otherwise deal with all or any part of the property and rights of the Company.<br/>
                                15. Subject to the provisions of the Companies Act, 2013 to lease, let out on hire, mortgage, pledge, hypothecate, sell or otherwise dispose of the whole or any part or parts of the undertaking of the Company or any land, business, property, rights or assets of any kind of the Company in such manner and for such consideration as the Company may think it.<br/>
                                16. ........... ++ More
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;