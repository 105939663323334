import React, { useEffect, useState } from "react";
import NumberCounter from 'number-counter';
import counter1 from '../../assets/images/counter-1.png';
import counter2 from '../../assets/images/counter-2.png';
import counter3 from '../../assets/images/counter-3.png';
// import counter4 from '../../assets/images/counter-4.png';

const CounterSection = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="section techwix-counter-section-02">
      <div className="container">
        <div className="counter-wrap">
          <div className="row">
            <div data-aos={isWideScreen ? "fade-right": "undefined"} className="col-xl-3 col-md-6">
              {/* Single Counter Start */}
              <div className="single-counter">
                <div className="counter-img">
                  <img src={counter1} alt="counter-1.png" />
                </div>
                <div className="counter-content">
                  <span className="counter"><NumberCounter start={0} end={25} delay={5} /></span>
                  <p>Happy clients</p>
                </div>
              </div>
              {/* Single Counter End */}
            </div>
            <div data-aos={isWideScreen ? "fade-right": "undefined"} className="col-xl-3 col-md-6">
              {/* Single Counter Start */}
              <div className="single-counter">
                <div className="counter-img">
                  <img src={counter2} alt="counter-2.png" />
                </div>
                <div className="counter-content">
                  <span className="counter"><NumberCounter start={0} end={100} delay={5} /></span>
                  <p>Finished projects</p>
                </div>
              </div>
              {/* Single Counter End */}
            </div>
            <div data-aos={isWideScreen ? "fade-right": "undefined"} className="col-xl-3 col-md-6">
              {/* Single Counter Start */}
              <div className="single-counter">
                <div className="counter-img">
                  <img src={counter3} alt="counter-3.png" />
                </div>
                <div className="counter-content">
                  <span className="counter"><NumberCounter start={0} end={20} delay={5} /></span>
                  <p>Skilled Experts</p>
                </div>
              </div>
              {/* Single Counter End */}
            </div>
            <div data-aos={isWideScreen ? "fade-right": "undefined"} className="col-xl-3 col-md-6">
              {/* Single Counter Start */}
              <div className="single-counter single-counter-4">
                <div className="counter-img">
                  <img src={counter1} alt="counter-1.png" />
                </div>
                <div className="counter-content">
                  <span className="counter"><NumberCounter start={0} end={1000} delay={5} /></span>
                  <p>Media Posts</p>
                </div>
              </div>
              {/* Single Counter End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
