import React, { useEffect, useState } from "react";
import SwiperCore, { Pagination , Autoplay} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import caseStudy from '../../assets/images/bg/case-study-bg.jpg';
import case4 from '../../assets/images/case-4.jpg';
import case5 from '../../assets/images/case-5.jpg';
import case6 from '../../assets/images/case-6.jpg';
import case7 from '../../assets/images/case-7.jpg';

const CaseStudySection = () => {
  SwiperCore.use([Autoplay, Pagination]);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div
      className="section techwix-case-study-section-02 section-padding"
      style={{ backgroundImage: `url(${caseStudy})` }}
    >
      <div className="container">
        <div className="case-study-wrap">
          <div className="section-title text-center">
            <h3 data-aos={isWideScreen ? "fade-left" : "undefined"} className="sub-title">Impactful Results</h3>
            <h2 data-aos={isWideScreen ? "fade-right" : "undefined"} className="title white">Case Studies from Our Expert Team</h2>
          </div>
        </div>
      </div>
      {/* Case Study Content Wrap Start */}
      <div className="case-study-content-wrap">
        <div className="swiper-container case-study-active">
          <div className="swiper-wrapper">
            <Swiper
              spaceBetween={15}
              slidesPerView={1}
              centeredSlides={true}
              breakpoints={{
                // When screen width is >= 640px (tablet and above), show 3 slides
                640: {
                  slidesPerView: 3,
                },
              }}
              onSlideChange={() => console.log('slide change')}
              loop={true}
              autoplay={{
                delay: 3000, // 3000 ms (3 seconds)
                disableOnInteraction: false, // Keeps autoplay working even after user interaction
              }}
              pagination={{ clickable: true }}
            >
              <div className="swiper-slide">
                <SwiperSlide>
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <a href="#">
                        <img src={case4} alt="case-4.jpg" />
                      </a>
                    </div>
                    <div className="case-study-content">
                      {/* <p className="sub-title">Web Development</p> */}
                      <h3 className="title">
                        <a href="#">
                          <span>Web </span> <br />
                          <span>Designing</span>
                        </a>
                      </h3>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
              <div className="swiper-slide">
                <SwiperSlide>
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <a href="#">
                        <img src={case5} alt="case-5.jpg" />
                      </a>
                    </div>
                    <div className="case-study-content">
                      {/* <p className="sub-title">Digital Marketing</p> */}
                      <h3 className="title">
                        <a href="#">
                          <span>Digital</span> <br />
                          <span>Marketing</span>
                        </a>
                      </h3>
                      {/* <p className="text">
                      From SEO to social media management, our comprehensive suite of services ensures your brand stands out in the crowded digital landscape.
                      </p> */}
                    </div>
                  </div>
                </SwiperSlide>
              </div>
              <div className="swiper-slide">
                <SwiperSlide>
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <a href="#">
                        <img src={case6} alt="case-6.jpg" />
                      </a>
                    </div>
                    <div className="case-study-content">
                      {/* <p className="sub-title">Graphic Design</p> */}
                      <h3 className="title">
                        <a href="#">
                          <span>Graphic</span> <br /> <span>Design</span>
                        </a>
                      </h3>
                      {/* <p className="text">
                      From stunning logos & eye-catching graphics to immersive web designs and multimedia content, we brings your vision to life with precision and flair.
                      </p> */}
                    </div>
                  </div>
                </SwiperSlide>
              </div>
              <div className="swiper-slide">
                <SwiperSlide>
                  <div className="single-case-study-02">
                    <div className="case-study-img">
                      <a href="#">
                        <img src={case7} alt="case-7.jpg" />
                      </a>
                    </div>
                    <div className="case-study-content">
                      {/* <p className="sub-title">BPO/KPO</p> */}
                      <h3 className="title">
                        <a href="#">
                          <span>BPO/</span> <br />
                          <span>KPO</span>
                        </a>
                      </h3>
                      {/* <p className="text">
                      We offer cutting-edge Business Process Outsourcing  and Knowledge Process Outsourcing solutions tailored to meet the diverse needs of businesses.
                      </p> */}
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
      {/* Case Study Content Wrap End */}
    </div>
  );
};

export default CaseStudySection;
