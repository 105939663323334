import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const SkillSection = () => {  
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="section techwix-skill-section-02 section-padding">
      <div className="container">
        <div className="skill-wrap">
          <div className="row">
            <div className="col-lg-6">
              <div className="skill-left">
                <div className="section-title">
                  <h2 data-aos={isWideScreen? "fade-right":"undefined"} className="title">
                    Preparing for your success, we provide truly prominent IT
                    solutions
                  </h2>
                </div>
                <div data-aos={isWideScreen? "fade-right":"undefined"} className="experience-wrap">
                  <div className="experience">
                    <h2 className="number">8</h2>
                    <span>
                      Years of <br /> experience
                    </span>
                  </div>
                  <div className="experience-text">
                    <p>
                      Celebrating several successful years of innovation, growth, and excellence at Bilvaleaf.
                      Marking a significant milestone, Bilvaleaf continues to thrive with a proven track record of success and customer satisfaction.
                    </p>
                    <Link className="learn-more" to="/about">
                      Learn More About Us
                      <i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="skill-right">
              <div className="counter-bar"><div className="skill-item"><span className="title">Web Development</span><div className="skill-bar"><div className="bar-inner"><div className="bar progress-line color-1" style={{width: "80%"}}><span className="skill-percent"><span className="counter">80</span>%</span></div></div></div></div><div className="skill-item"><span className="title">Digital Marketing</span><div className="skill-bar"><div className="bar-inner"><div className="bar progress-line color-1" style={{width: "95%"}}><span className="skill-percent"><span className="counter">95</span>%</span></div></div></div></div><div className="skill-item"><span className="title">Visual Designing</span><div className="skill-bar"><div className="bar-inner"><div className="bar progress-line color-1" style={{width: "80%"}}><span className="skill-percent"><span className="counter">80</span>%</span></div></div></div></div><div className="skill-item"><span className="title">BPO/KPO</span><div className="skill-bar"><div className="bar-inner"><div className="bar progress-line color-1" style={{width: "90%"}}><span className="skill-percent"><span className="counter">90</span>%</span></div></div></div></div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillSection;
