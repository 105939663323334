import React, { useState, useEffect } from "react";
import './App.css';
import Layout from './Component/layout/Layout';
import { BrowserRouter as Router } from "react-router-dom";
import Swiper from "swiper";
import AOS from "aos";
import { HelmetProvider } from "react-helmet-async";
import MainLayout from "./Component/layout/MainLayout.jsx";

function App() {
  useEffect(() => {
    // Preloader
    const handlePreloader = () => {
      const preloader = document.getElementById("preloader");
      if (preloader) {
        setTimeout(() => {
          preloader.style.display = "none";
        }, 500);
      }
    };

    handlePreloader();
    // Initialize AOS
    AOS.init({
      duration: 1200,
      once: true,
    });
    // Initialize Swiper sliders
    new Swiper(".case-study-active", {
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      pagination: {
        el: ".case-study-active .swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        0: { slidesPerView: 1 },
        576: { slidesPerView: 1 },
        768: { slidesPerView: 1 },
        992: { slidesPerView: 2 },
        1200: { slidesPerView: 3 },
      },
    });

    // Other Swiper sliders initialization code...
  }, []);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smooth scrolling
    });
  };
  return (
    <HelmetProvider>
      <MainLayout />
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          &#8679;
        </button>
      )}
    </HelmetProvider>
  );
}

export default App;
