import React, { useEffect, useState } from "react";
import serviceBg3 from '../../assets/images/bg/service-bg3.jpg';
import serviceIcon from '../../assets/images/ser-icon9.png';
import serviceIcon10 from '../../assets/images/ser-icon10.png';
import serviceIcon11 from '../../assets/images/ser-icon11.png';
import serviceIcon12 from '../../assets/images/ser-icon12.png';
import { Link } from "react-router-dom";

const ServiceSection = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 429);
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 429);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div
      className="section techwix-service-section-03"
      style={{ backgroundImage: `url(${serviceBg3})` }}
    >
      <div className="container">
        {/* Service Wrap Start */}
        <div className="service-wrap-03">
          <div className="row">
            <div data-aos={isWideScreen ? "zoom-in" : "undefined"} className="col-xl-3 col-md-6">
              {/* Service Item Start */}
              <div className="service-item-03">
                <div className="service-img">
                  <img src={serviceIcon} alt="ser-icon9.png" />
                </div>
                <div className="service-content">
                  <h3 className="title">
                    <Link to="/service">Web Development</Link>
                  </h3>
                  <p>
                  Pioneers in Web Development & web development, we specialize in crafting stunning websites and intuitive web portals tailored to your unique needs.
                  </p>
                  <div className="read-more">
                    <Link to="/service">
                      <i className="fas fa-plus"></i> Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* Service Item End */}
            </div>
            <div data-aos={isWideScreen ? "zoom-in" : "undefined"} className="col-xl-3 col-md-6">
              {/* Service Item Start */}
              <div className="service-item-03">
                <div className="service-img">
                  <img src={serviceIcon10} alt="ser-icon10.png" />
                </div>
                <div className="service-content">
                  <h3 className="title">
                    <Link to="/service">Digital Marketing</Link>
                  </h3>
                  <p>
                  From SEO to social media management, our comprehensive suite of services ensures your brand stands out in the crowded digital landscape.
                  </p>
                  <div className="read-more">
                    <Link to="/service">
                      <i className="fas fa-plus"></i> Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* Service Item End */}
            </div>
            <div data-aos={isWideScreen ? "zoom-in" : "undefined"} className="col-xl-3 col-md-6">
              {/* Service Item Start */}
              <div className="service-item-03">
                <div className="service-img">
                  <img src={serviceIcon11} alt="ser-icon11.png" />
                </div>
                <div className="service-content">
                  <h3 className="title">
                    <Link to="/service">Graphic Design</Link>
                  </h3>
                  <p>
                  From stunning logos & eye-catching graphics to immersive web designs and multimedia content, we brings your vision to life with precision and flair.
                  </p>
                  <div className="read-more">
                    <Link to="/service">
                      <i className="fas fa-plus"></i> Read More
                    </Link>
                  </div>
                </div>
              </div>
              {/* Service Item End */}
            </div>
            <div data-aos={isWideScreen ? "zoom-in" : "undefined"} className="col-xl-3 col-md-6">
              {/* Service Item Start */}
              <div className="service-item-03">
                <div className="service-img">
                  <img src={serviceIcon12} alt="ser-icon12.png" />
                </div>
                <div className="service-content">
                  <h3 className="title">
                    <Link to="/service">BPO/KPO</Link>
                  </h3>
                  <p>
                  We offer cutting-edge Business Process Outsourcing  and Knowledge Process Outsourcing solutions tailored to meet the diverse needs of businesses.
                  </p>
                  <div className="read-more">
                    <Link to="/service">
                      <i className="fas fa-plus"></i> Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
